class Role {
  constructor() {}

  rolesJSON = {
    roles: [
      {
        role_id: 1,
        role: "User",
        access_properties: {
          Project: {
            read: false,//action, active . inactive
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false, //Action
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Company: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
    
        },
      },
      {
        role_id: 2,
        role: "Admin",
        access_properties: {
          Project: {
            read: true, //Action
            write: true,
            delete: true,
            update: true,
          },
          Task: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
          Department: {
            read: true, //Action
            write: false,
            delete: false,
            update: true,
          },
          Member: {
            read: true, //Action
            write: true,
            delete: true,
            update: true,
          },
          Company: {
            read: true,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 3,
        role: "Super Admin",
        access_properties: {
          Project: {
            read: true, //Active/i-active, action
            write: true,
            delete: true,
            update: true,
          },
          Task: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
          Department: {
            read: true, //Action
            write: true,
            delete: true,
            update: true,
          },
          Member: {
            read: true, //Action
            write: true,
            delete: true,
            update: true,
          },
          Company: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
        },
   
      },
      {
        role_id: 4,
        role: "Organization Owner",
        access_properties: {
          Project: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
          Task: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
          Department: {
            read: true,
            write: false,
            delete: false,
            update: true,
          },
          Member: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
        },
      },
      {
        role_id: 5,
        role: "Organization Member",
        access_properties: {
          Project: {
            read: true,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: true,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 6,
        role: "Product Owner",
        access_properties: {
          Project: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
          Task: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
          Department: {
            read: false,
            write: true,
            delete: true,
            update: true,
          },
          Member: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
        },
      },
      {
        role_id: 7,
        role: "Project Manager",
        access_properties: {
          Project: {
            read: true,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: true,
            write: true,
            delete: true,
            update: true,
          },
          Department: {
            read: true,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: true,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 8,
        role: "Project Member",
        access_properties: {
          Project: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 9,
        role: "Developer",
        access_properties: {
          Project: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 10,
        role: "QA",
        access_properties: {
          Project: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 11,
        role: "Designer",
        access_properties: {
          Project: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 14,
        role: "Intern",
        access_properties: {
          Project: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 12,
        role: "Digital Marketing Manager",
        access_properties: {
          Project: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 13,
        role: "Other",
        access_properties: {
          Project: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
      {
        role_id: 15,
        role: "DevOps",
        access_properties: {
          Project: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Task: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Department: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
          Member: {
            read: false,
            write: false,
            delete: false,
            update: false,
          },
        },
      },
    ],
  };

  // Function to check access based on role_id, resource, and action
  checkAccess(role_id, resource, action) {
    let role = this.rolesJSON.roles.find((role) => role.role_id == role_id);
    // console.log(
    //   role_id,
    //   resource,
    //   action,
    //   role.access_properties[resource][action]
    // );
    return role.access_properties[resource][action];
  }
}

// Export the checkAccess method from the instance
module.exports = Role;
