<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest"
import Utils from "@/helpers/Utils"
import Preference from "@/data/Preference"
import Constants from "@/data/Constants"
import Swal from "sweetalert2";
import {bus} from "@/views/pages/dashboard/index";
import Role from "@/data/Roles";

let roleChecker = new Role();

let preference = new Preference()
let httpRequest = new HttpRequest()

export default {
  data() {
    return {
      isImageLoading: true,
      title: "Activities",
      role: preference.getData(Preference.KEY_ROLE_ID),
      items: [
        {
          text: "WORKANY",
          to:'/'
        },
        {
          text: "Activities",
          active: true
        }
      ],
      isLoading: false,
      isEmpty: false,
      isLoadingMembers: false,
      selectedFiles: [],
      selectedNames: [],
      selectedFileIndex: -1,
      imageIndex: 0,
      projectListSelectOption: [],
      projectIdList: [],
      selectedProjectId: null,
      selectedProjectName: [],
      memberListSelectOption: [],
      selectedMemberId: null,
      selectedMemberName: [],
      date: null,
      datePickerPlaceholder: 'Today',
      activitiesData: [],
      isOwnerOfTheSelectedProject: false,
      slide: 0,
      delay: preference.getData(Preference.KEY_AUTO_REFRESH_TIME),
      runner: null,
      editedItem: "",
      selectedActivityType: 0,
      options: [
        {text: 'Task', value: 0},
        {text: 'Meetings', value: 1},
        {text: 'Others', value: 2,},
      ],
      all: {
        id: null,
        name: 'All'
      },
      me: {
        id: null,
        name: 'Me'
      },
      summaryData: [
        /* {
           title: "Activity",
           icon: "ri-line-chart-fill",
           value: ""
         },*/

        {
          title: "Total ",
          icon: "ri-time-line",
          value: ""
        },
        {
          title: "Thinking ",
          icon: "ri-time-line",
          value: ""
        },
        {
          title: "performance",
          icon: "ri-time-line",
          value: ""
        },
        /*{
          title: "Active",
          icon: "ri-timer-flash-line",
          value: ""
        }*/
      ],
    }
  },
  components: {
    Layout,
    PageHeader
  },
  methods: {
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id, resource, action);
    },
    getActivities() {
      let _this = this
      let formattedDate = Utils.formatDate(this.date)
      let timezone = Utils.getMyTimezone();

      _this.isLoading = true
      httpRequest.getDashboardActivities(formattedDate, formattedDate, timezone, _this.selectedProjectId, _this.selectedMemberId).then(function (response) {
        _this.isLoading = false
        // _this.isImageLoading = false

        if (response.success && response.data.summary != null && response.data.summary.total_time != null) {
          _this.isEmpty = false

          /*  let percent = Math.round(((response.data.summary.total_time - response.data.summary.idle_time) * 100) / response.data.summary.period_time)
            if (isNaN(percent)) {
              percent = 0
            }
            // percent data should below 100
            if (isNaN(percent)) {
              percent = 0
            }
            if (percent > 100) {
              percent = 100
            }*/

          // Those data are hide based on discussion
          // Ticket Link: projects.w3engineers.com/browse/TT-320
          /*_this.summaryData[0].value = percent + '%'
          _this.summaryData[1].value = Utils.secToTime(response.data.summary.total_time)
          _this.summaryData[2].value = Utils.secToTime(response.data.summary.total_time - response.data.summary.idle_time)*/

          _this.summaryData[0].value = Utils.secToTime(response.data.summary.total_time)
          _this.summaryData[1].value = Utils.secToTime(response.data.summary.idle_time)
          _this.summaryData[2].value = Math.ceil(((response.data.summary.total_time - response.data.summary.idle_time)*100)/response.data.summary.total_time)
          _this.activitiesData = []

          let hourSlotWiseData = []
          response.data.date_data.forEach(function (data) {
            if (data) {
              data.day_data.forEach(function (data) {
                let totalTime = data.total_time
                // wrong data
                if (totalTime > 600) {
                  totalTime = 600
                }
                let idleTime = data.idle_time
                // wrong data
                if (idleTime > 600) {
                  idleTime = 200
                }
                let startTime = data.start_time
                let endTime = data.end_time
                let activeTime = totalTime - idleTime
                let comment = data.comment
                let activityType = Utils.getActivityType(data.type)
                let type = data.type
                let edited = data.edited
                //let periodTime = Utils.getSecDiff(startTime, endTime)
                let percent = Math.round((activeTime * 100) / totalTime)
                // percent data should below 100
                if (percent > 99) {
                  percent = 96
                }
                let totalMouseKeyboard = parseInt(data.mouse_time) + parseInt(data.keyboard_time)
                let mousePercent = Math.round((data.mouse_time * 100) / totalMouseKeyboard)
                let keyboardPercent = Math.round((data.keyboard_time * 100) / totalMouseKeyboard)


                // TODO: Optimize image loading with lazy loader instead of preload
                let screenshots = [], screenshotNames = [], userName = [], projectName = [], thumbName = [];

                if (!Utils.isNull(data.thumb_names)) {
                  data.thumb_names.split(',').forEach((thumb) => {
                    // thumbName = Constants.CLOUDEFRONT_SIGNED_URL + thumb
                    _this.getImage(thumb).then(function (response) {
                      _this.isImageLoading = false

                      thumbName.push(response)
                    })
                  })
                }

                if (!Utils.isNull(data.screenshots)) {
                  data.screenshots.forEach(function (item) {
                    if (item.screenshot_url) {
                      item.screenshot_url.split(',').forEach(function (imageName) {
                        if (imageName) {
                          _this.getImage(imageName).then(function (response) {
                            screenshotNames.push(imageName)
                            screenshots.push(response)
                          })
                          // screenshotNames.push(imageName)
                          // screenshots.push(Constants.CLOUDEFRONT_SIGNED_URL + imageName)
                        }
                      })
                    }
                    if (item.user) {
                      item.user.forEach(function (name) {
                        userName.push(name)
                      })
                    }
                    if (item.project) {
                      item.project.forEach(function (name) {
                        projectName.push(name)
                      })
                    }
                  })
                }


                let activityData = {
                  activityPercent: percent,
                  mousePercent: mousePercent,
                  keyboardPercent: keyboardPercent,
                  hourSlot: Utils.getHourRange(startTime),
                  minuteRange: Utils.getMinuteRange(startTime, endTime),
                  hour: parseInt(Utils.getHour(startTime)),
                  minute: parseInt(Utils.getMinute(startTime)),
                  screenshots: screenshots,
                  thumb_name: thumbName,
                  screenshotNames: screenshotNames,
                  user: userName,
                  project: projectName,
                  comment: comment,
                  type: type,
                  activityType: activityType,
                  activityId: data.activity_id,
                  edited: edited,
                  totalTime: Utils.secToTime(totalTime)
                }
                hourSlotWiseData.push(activityData)
              })
            }
          })

          let hourSlotWiseObjectData = Utils.groupByKey(hourSlotWiseData, 'hourSlot')
          for (let key of Object.keys(hourSlotWiseObjectData)) {
            let hourSlotWiseData = hourSlotWiseObjectData[key]

            hourSlotWiseData.sort(_this.sortDateByMinute);
            let activityData = {
              hourSlot: key,
              hour: hourSlotWiseData[0].hour,
              data: hourSlotWiseData
            }
            _this.activitiesData.push(activityData)
          }

          // sort by hour
          _this.activitiesData.sort(_this.sortDateByHour);

        } else {
          _this.isEmpty = true
          _this.activitiesData = []
        }
        _this.isImageLoading = true


      })
    },
    getProjectList() {
      let _this = this
      _this.isLoading = true
      let accessPermission = _this.isRoleAccess(this.role, 'Project', 'read')

      return httpRequest.getProjectList(accessPermission).then(function (response) {
        _this.isLoading = false
        if (response.success) {
          response.data.forEach(function (projectData) {
            if(projectData.project.project_status == 1) {
              let project = {
                id: projectData.project.project_id,
                name: projectData.project.project_name
              }
              _this.projectListSelectOption.push(project)
              if (_this.isRoleAccess(projectData.role_id, 'Project', 'read')) {
                _this.projectIdList.push(projectData.project.project_id)
              }
            }
          })
        }
      })
    },
    getProjectMemberList() {
      let _this = this
      _this.memberListSelectOption = []
      if (_this.selectedProjectId != null) {
        _this.isLoadingMembers = true
        httpRequest.getProjectMembers(_this.selectedProjectId).then(function (response) {
          _this.isLoadingMembers = false
          if (response.success) {

            let myUserId = preference.getData(Preference.KEY_USER_ID)
            _this.isOwnerOfTheSelectedProject = false
            response.data.forEach(function (member) {
              let myRoleId = -1
              if (member.user.user_id === myUserId) {
                _this.selectedMemberId = member.user.user_id
                myRoleId = member.role_id

                _this.isOwnerOfTheSelectedProject = _this.isRoleAccess(myRoleId, 'Member', 'read')
              }

              let user = {
                id: member.user.user_id,
                name: member.user.name
              }
              _this.memberListSelectOption.push(user)

            })

            if(_this.isOwnerOfTheSelectedProject == false && _this.isRoleAccess(_this.role, 'Member', 'read') == false) {
              _this.memberListSelectOption = []
              let user = {
                id: _this.selectedMemberId,
                name: preference.getData(Preference.KEY_NAME)
              }
              _this.memberListSelectOption.push(user)
            }
          }
        })
      } else {
        _this.getAllMembersBasedOnProjectList()
      }
    },
    getAllMembersBasedOnProjectList() {
      let _this = this
      _this.memberListSelectOption = []
      if (_this.projectIdList.length > 0) {
        _this.isLoadingMembers = true
        httpRequest.getProjectMembers(_this.projectIdList).then(function (response) {
          _this.isLoadingMembers = false
          if (response.success) {
            let myUserId = preference.getData(Preference.KEY_USER_ID)
            _this.isOwnerOfTheSelectedProject = false
            response.data.forEach(function (member) {

              if (member.user !== null) {
                let myRoleId = -1
                if (member.user.user_id === myUserId) {
                  _this.selectedMemberId = member.user.user_id
                  myRoleId = member.role_id

                  _this.isOwnerOfTheSelectedProject = myRoleId === Constants.ROLE_PRODUCT_OWNER;
                }
                let user = {
                  id: member.user.user_id,
                  name: member.user.name
                }
                _this.memberListSelectOption.push(user)
              }
            })
            // remove duplicate if exist
            if (_this.memberListSelectOption.length > 0) {
              const key = 'id';
              _this.memberListSelectOption = [...new Map(_this.memberListSelectOption.map(item =>
                  [item[key], item])).values()];
            } else {
              _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID)
              _this.me.id = _this.selectedMemberId
              _this.me.name = preference.getData(Preference.KEY_NAME)
              _this.memberListSelectOption.push(this.me)
            }
          }
        })
      } else {
        _this.selectedMemberId = preference.getData(Preference.KEY_USER_ID)
        _this.me.id = _this.selectedMemberId
        _this.me.name = preference.getData(Preference.KEY_NAME)
        _this.memberListSelectOption.push(this.me)
      }
    },
    loadSelectedData() {
      this.getActivities()
    },
    loadInitialData() {
      this.date = Utils.getToday().replaceAll("/", "-")
      this.projectListSelectOption.push(this.all)
      if (this.selectedMemberId == null) {
        this.selectedMemberId = preference.getData(Preference.KEY_USER_ID)
        this.me.id = this.selectedMemberId
        this.me.name = preference.getData(Preference.KEY_NAME)
      }
      this.memberListSelectOption.push(this.me)

      this.getActivities()
    },
    sortDateByHour(a, b) {
      if (a.hour < b.hour) {
        return 1;
      }
      if (a.hour > b.hour) {
        return -1;
      }
      return 0;
    },
    sortDateByMinute(a, b) {
      if (a.minute < b.minute) {
        return -1;
      }
      if (a.minute > b.minute) {
        return 1;
      }
      return 0;
    },
    getTooltipText(totalTime, mouse, keyboard, images) {
      return "Total Time: " + totalTime
          + "\n Mouse: " + mouse + "%, Keyboard: " + keyboard
          + "%,\n Total Screenshots: " + images
    },
    getEditCommentTooltip(type, comment) {
      if (comment) {
        return type + " | " + comment
      }
    },
    invokeModal(modalId, selectedNames, selectedFiles, selectedUser, selectedProject) {
      this.selectedNames = selectedNames
      this.selectedFiles = selectedFiles
      this.selectedMemberName = selectedUser
      this.selectedProjectName = selectedProject
      this.slide = 0

      if (this.selectedFiles.length > 0) {
        this.$bvModal.show(modalId)
      } else {
        this.$bvToast.toast('Either removed or you don\'t have enough permission to see this!', {
          title: 'No screenshot!',
          variant: 'warning',
          solid: true
        })
      }
    },
    selectFileIndex(index) {
      this.selectedFileIndex = index
    },
    removeImage(modalId) {
      let _this = this
      Swal.fire({
        title: "Are you sure?",
        text: "You want to remove this image?",
        showCancelButton: true,
        icon: "warning",
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          let imageName = _this.selectedNames[this.slide]
          httpRequest.deleteFile(imageName).then(function (response) {
            if (response.success) {
              _this.$bvModal.hide(modalId)
              Swal.fire("Deleted!", "Your file has been deleted.", "success")
            } else {
              Swal.fire("Failed!", "Either you don't have authorization to remove this file or something went wrong!", "error")
            }
            _this.loadSelectedData()
          })

        }
      })
    },
    async getImage(image) {
      //return Constants.AWS_FILE_BASE_URL + image
      await httpRequest.generateImageUrl(image).then(function (response) {
        if (response.success) {
          image = response.data.URL
        }
      })
      return image
    },
    onSlideStart(slide) {
      this.slide = slide
    },
    onSlideEnd(slide) {
      this.slide = slide
    },
    setEditedActivity(item) {
      this.editedItem = item
      this.selectedActivityType = item.type
    },
    updateActivityData(modalId) {
      let _this = this
      let activityId = this.editedItem.activityId
      let comment = this.editedItem.comment
      let type = this.selectedActivityType
      if (comment) {
        httpRequest.editActivityData(activityId, comment, type).then(function (response) {
          if (response.success) {
            _this.$bvModal.hide(modalId)
            Swal.fire("Updated!", "Activity data updated successfully", "success").then(result => {
              if (result) {
                _this.getActivities()
              }
            })
          } else {
            _this.$bvModal.hide(modalId)
            Swal.fire("Failed!", "Either you don't have authorization to edit this activity or something went wrong!", "error")
          }
        })
      } else {
        Swal.fire("Failed!", "Please add a comment also", "error")
      }
    }
  },
  async created() {
    // auto refresh by updated value
    bus.$on('auto_refresh_val', data => {
      this.delay = data
      clearInterval(this.runner)
      if (this.delay > 0) {
        this.runner = setInterval(this.loadSelectedData, this.delay * 60 * 1000)
      }
    })
    await this.getProjectList()
    await this.loadInitialData()
    await this.getAllMembersBasedOnProjectList()
    // auto refresh by previously set value
    clearInterval(this.runner)
    if (this.delay > 0) {
      this.runner = setInterval(this.loadSelectedData, this.delay * 60 * 1000)
    }

  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col mb-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 col-md col-xl-4">
                <label>Date</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                           <i class="ri-calendar-line customs-text-color"></i>
                         </span>
                      </b-input-group-prepend>
                      <b-form-datepicker
                          id="from-datepicker"
                          class="date_pick"
                          v-model="date"
                          :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                          placeholder="Today"
                      ></b-form-datepicker>
                    </b-input-group>
                  </div>
                </template>

              </div>
              <div class="col-sm-6 col-md-3">
                <label>Project</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                           <i class="ri-folder-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-select
                          :options="projectListSelectOption"
                          v-model="selectedProjectId"
                          value-field="id"
                          text-field="name"
                          v-on:change="getProjectMemberList"
                      ></b-form-select>
                    </b-input-group>
                  </div>
                </template>

              </div>
              <div class="col-sm-6 col-md-3">
                <label>Member</label>
                <template>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <span class="input-group-text">
                           <i class="ri-user-3-line customs-text-color"></i>
                        </span>
                      </b-input-group-prepend>

                      <b-form-select
                          :options="memberListSelectOption"
                          v-model="selectedMemberId"
                          value-field="id"
                          text-field="name"
                          :disabled="isLoadingMembers"
                      ></b-form-select>
                    </b-input-group>
                  </div>
                </template>
              </div>
              <div class="col-sm-6 col-md-auto col-xl-2">
                <label>Action</label>
                <b-button
                    block
                    class="customs-bg-color"
                    v-on:click="loadSelectedData"
                ><i class="ri-eye-line " style="vertical-align:bottom"></i> VIEW
                </b-button>
              </div>
            </div>

            <!-- <div class="row mt-4" v-if="!isLoading && !isEmpty">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-12 mb-4" v-for="(data, index) in summaryData" :key="index">
                    <div class="card">
                      <div class="card-body">
                        <div class="media">
                          <div class="media-body overflow-hidden">
                            <p class="text-truncate font-size-14 mb-2 text-center">{{ data.title }}</p>
                            <h4 class="mb-0 text-center">{{ data.value }}</h4>
                          </div>
                          <div class="text-primary text-center">
                            <i :class="`${data.icon} font-size-24`"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="alert alert-info text-center"
                 style="background: #fff;border: none; margin-top: 20px; position:relative;padding: 0"
                 role="alert" v-if="!isLoading && !isEmpty">
              <div class="alert alert-info- mt-3- pb-1 d-flex justify-content-between align-items-center  "
                   style="border: 1px solid #ced4da">
                <div v-for="(data, index) in summaryData" :key="index">
                  <h6 class="customs-text-color font-weight-bold text-uppercase" >
                    {{ data.title }} : {{ data.value }}<span v-if="data.title =='performance'">%</span></h6>
                </div>

                </div>
            </div>
            <div class="row mt-2" v-if="!isLoading && !isEmpty">
              <div class="col-12">
                <div data-simplebar>
                  <ul class="list-unstyled activity-wid">
                    <li class="activity-list" v-for="(data, parentIndex) in activitiesData" :key="parentIndex">
                      <div class="activity-icon avatar-xs">
                    <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                      <i class="ri-checkbox-blank-circle-fill"></i>
                    </span>
                      </div>
                      <div>
                        <div>
                          <h5>
                            {{ data.hourSlot }}
                            <!--<small class="text-muted">{{data.end_time}}</small>-->
                          </h5>
                        </div>

                        <div class="row mt-2">

                          <div class="col-6 col-sm-4 col-xl-2 mt-3" v-for="(data, childIndex) in data.data"
                               :key="childIndex">

                            <div style="background: #eeeeee; cursor: pointer;"
                                 v-on:click="invokeModal('img-view', data.screenshotNames, data.screenshots, data.user,data.project)"
                                 class="card mb-4" v-b-tooltip.hover
                                 :title="getEditCommentTooltip( data.activityType,data.comment)">
                              <div class="row" v-if="isImageLoading">
                                <div class="col-12" style="min-height: 118px;">
                                  <div class="card h-100">
                                    <div class="card-body">
                                      <div class="text-center mt-4 mb-4">
                                        <b-spinner class="align-middle"></b-spinner>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-if="!isImageLoading">
                                <img class="card-img" :src="data.thumb_name[0]?data.thumb_name[0]:data.screenshots[0]"
                                     height="90px" width="100%"
                                     style="object-fit: cover;">
                                <div class="card-img-overlay d-flex">
                                   <span v-if="data.edited"
                                         :class="['badge badge-pill align-self-center mx-auto','badge-info']">
                                    Edited</span>

                                  <span v-else :class="['badge badge-pill align-self-center mx-auto',
                                  (data.activityPercent <= 30 ? 'badge-danger' : 'badge-primary'),
                                  (data.activityPercent <= 60 ? 'badge-warning' : 'badge-primary'),
                                  (data.activityPercent > 60 ? 'badge-success' : 'badge-primary')]">
                                    {{ data.activityPercent }}%</span>
                                </div>
                              </div>
<!--                              <img class="card-img" :src="data.thumb_name[0]?data.thumb_name[0]:data.screenshots[0]"-->
<!--                                   height="90px" width="100%"-->
<!--                                   style="object-fit: cover;">-->
<!--                              <div class="card-img-overlay d-flex">-->
<!--                                 <span v-if="data.edited"-->
<!--                                       :class="['badge badge-pill align-self-center mx-auto','badge-info']">-->
<!--                                  Edited</span>-->

<!--                                <span v-else :class="['badge badge-pill align-self-center mx-auto',-->
<!--                                (data.activityPercent <= 30 ? 'badge-danger' : 'badge-primary'),-->
<!--                                (data.activityPercent <= 60 ? 'badge-warning' : 'badge-primary'),-->
<!--                                (data.activityPercent > 60 ? 'badge-success' : 'badge-primary')]">-->
<!--                                  {{ data.activityPercent }}%</span>-->
<!--                              </div>-->
                            </div>
                            <h6 class="font-size-13">{{ data.minuteRange }}</h6>
                            <div class="row mt-2">
                              <div class="col pr-0">
                                <b-progress v-b-tooltip.hover
                                            :title="getTooltipText(data.totalTime,data.mousePercent, data.keyboardPercent, data.screenshots.length)"
                                            height="5px" :value="data.activityPercent" class="mt-3"></b-progress>
                              </div>
                              <div class="col-auto pl-0">
                                <i class="ri-chat-new-line" style="font-size: 22px; color:#4b1fe5"
                                   v-b-modal.EditActivityModal
                                   @click="setEditedActivity(data)"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row mt-5 d-flex justify-content-center" v-if="isLoading">
              <b-spinner class="align-middle"></b-spinner>
            </div>

            <div class="alert alert-info mt-3 d-flex justify-content-center" v-if="isEmpty">
              No data to show!
            </div>
          </div>
        </div>

      </div>

      <b-modal id="img-view" size="xl" centered :title="selectedMemberName[slide]+' | '+selectedProjectName[slide]"
               title-class="font-18"
      >

        <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="600000"
            img-width="100%"
            controls
            indicators
            background="#ababab"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start=onSlideStart
            @sliding-end=onSlideEnd
        >
          <b-carousel-slide v-for="(data, index) in selectedFiles" :key="index" :img-src="data" alt="image slot"
                            style="min-height: 500px">
            {{ selectFileIndex(index) }}
          </b-carousel-slide>
        </b-carousel>

        <template #modal-footer>
          <h5></h5>
          <b-button v-if="isOwnerOfTheSelectedProject" size="sm"
                    variant="outline-danger"
                    :disabled="role!=3"
                    @click="removeImage('img-view')">
            REMOVE IMAGE
          </b-button>
        </template>
      </b-modal>

      <b-modal id="EditActivityModal" centered :title="'Edit Activity ('+editedItem.minuteRange+')'" hide-footer>
        <form class="form-horizontal" @submit.prevent="">

          <div class="form-group auth-form-group-custom mb-4">
            <i class="ri-file-edit-line auti-custom-input-icon"></i>
            <label for="task_title">Comment</label>
            <input
                type="text"
                class="form-control"
                id="task_title"
                placeholder="Enter comment"
                v-model=editedItem.comment
            />

          </div>
          <div class="form-group mb-4">
            <b-form-group label="Activity Type" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                  id="radio-group-1"
                  v-model="selectedActivityType"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
              ></b-form-radio-group>

            </b-form-group>
          </div>
          <div class="mt-4 text-center">
            <b-button
                variant="primary"
                size=""
                class="customs-bg-color"
                type="submit"
                v-on:click="updateActivityData('EditActivityModal')"
            >UPDATE <i class="ri-arrow-right-s-line" style="vertical-align:bottom"></i>
            </b-button>
          </div>

        </form>
      </b-modal>

    </div>
  </Layout>
</template>

<style >

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}
.customs-text-color {
  color: #ff7c47;
}


#from-datepicker > svg{
  display: none;
}

</style>
